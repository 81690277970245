import { Text, Textarea, useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import NorpayButton from 'components/Button/NorpayButton'
import { queryKeys } from 'data/query-keys'
import { api } from 'lib/query-client'
import { useAuth } from 'providers/AuthProvider'
import { useState } from 'react'
import { ApplyCardInput, RegisterCardInput } from 'types/api'
import { DepositInfo } from 'types/deposit'
import Modal from './Modal'
import { reportErrorToSentry } from 'lib/error-reporting'

interface Props {
  isOpen: boolean
  onClose: () => void
  cardId?: string
  cardTypeId?: string
  agencyCode?: string
}

const DepositRecoverModal = ({ isOpen, onClose, cardId, cardTypeId, agencyCode }: Props) => {
  const toast = useToast()

  const queryClient = useQueryClient()

  const { user } = useAuth()

  const [referenceInput, setReferenceInput] = useState('')

  const { mutate: applyCard, isPending: isApplying } = useMutation({
    mutationFn: ({
      cardTypeId: cardTypeIdInput,
      ...data
    }: ApplyCardInput & { cardTypeId: string }) =>
      api.post(`cards/apply/${cardTypeIdInput}`, { json: data }),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queryKeys.GET_CARDS })
      onClose()
    },
    onError: error => {
      toast({
        title: 'Error',
        description: error.message ?? 'applying for card has failed. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const { mutate: recharge, isPending: isRechargingCard } = useMutation({
    mutationFn: (data: { reference: string; cardId: string }) =>
      api.post(`cards/${data.cardId}/recharge`, { json: { reference: data.reference } }),
    onSuccess: async (data, variables) => {
      await queryClient.refetchQueries({ queryKey: queryKeys.GET_CARD_BALANCE(variables.cardId) })

      toast({
        title: 'Deposit successful',
        status: 'success',
        isClosable: true,
      })
      onClose()
    },
    onError(error) {
      toast({
        title: 'Error',
        description: error.message ?? 'Deposit has failed. Please try again later.',
        status: 'error',
        isClosable: true,
      })
    },
  })

  const { mutate: registerCard, isPending: isRegisteringCard } = useMutation({
    mutationKey: ['register-card'],
    mutationFn: async (data: RegisterCardInput) =>
      api.post('cards/register', { json: data }).json(),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queryKeys.GET_CARDS })
      onClose()
    },
    onError: () => {
      toast({
        title: 'Error',
        description: 'Card registration has failed. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const handleRecover = async () => {
    if (!referenceInput) {
      toast({
        title: 'Error',
        description: 'Please enter a deposit reference.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    try {
      if (cardId) {
        return recharge({ reference: referenceInput, cardId })
      }

      if (!agencyCode) {
        toast({
          title: 'Error',
          description: 'Agency code is missing.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return
      }

      if (cardTypeId) {
        return applyCard({ reference: referenceInput, cardTypeId, agencyCode })
      }
      // const deposit = await queryClient.fetchQuery<DepositInfo>({
      //   queryKey: queryKeys.GET_DEPOSIT_BY_REFERENCE(referenceInput),
      // })
      // if (deposit.type === 'application') {
      //   return applyCard({ reference: referenceInput, cardTypeId: cardTypeId!, agencyCode })
      // } else if (deposit.type === 'recharge') {
      //   return recharge({ reference: referenceInput, cardId: cardId! })
      // } else if (deposit.type === 'registration') {
      //   return registerCard({
      //     cardTypeId: cardTypeId!,
      //     reference: referenceInput,
      //     name: user?.name ?? '',
      //     surname: user?.surname ?? '',
      //     email: user?.email ?? '',
      //     phoneCode: user?.phoneCode?.toString() ?? '',
      //     phoneNumber: user?.phoneNumber ?? '',
      //     country: user?.country ?? '',
      //     town: user?.town ?? '',
      //     buildingNumber: user?.buildingNumber ?? '',
      //     street: user?.street ?? '',
      //     postcode: user?.postcode ?? '',
      //     flatNumber: user?.flatNumber ?? '',
      //     state: user?.state ?? '',
      //     agencyCode,
      //   })
      // } else {
      //   toast({
      //     title: 'Error',
      //     description: 'Invalid deposit reference.',
      //     status: 'error',
      //     duration: 5000,
      //     isClosable: true,
      //   })
      // }
    } catch (error) {
      reportErrorToSentry(error)
      toast({
        title: 'Error',
        description: 'Invalid deposit reference.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal title="Recover deposit" size="md" isOpen={isOpen} onClose={onClose}>
      <Textarea
        placeholder="Enter deposit reference"
        borderColor="#53545B"
        isDisabled={isApplying || isRechargingCard || isRegisteringCard}
        value={referenceInput}
        onChange={e => setReferenceInput(e.target.value)}
      />
      <Text fontSize="sm" mt={4}>
        Deposit reference is a string (starting with 0x) that was provided when the deposit
        transaction failed. Paste it in the field above and click the Recover button to recover your
        deposit.
      </Text>
      <NorpayButton
        w="full"
        bgGradient="linear-gradient(159deg, #5317FF 14.06%, #591ED2 94.29%)"
        fontSize={18}
        fontWeight={600}
        fontFamily="Inter Variable"
        py={4}
        mt={7}
        maxW="none"
        onClick={handleRecover}
        isLoading={isApplying || isRechargingCard || isRegisteringCard}
        loadingText={'Processing...'}
      >
        Recover
      </NorpayButton>
    </Modal>
  )
}

export default DepositRecoverModal
