import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Text,
  useBoolean,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CopyButton, CountrySelect } from 'components'
import NorpayButton from 'components/Button/NorpayButton'
import CountryCodeSelect from 'components/CountryCodeSelect/CountryCodeSelect'
import DepositRecoverModal from 'components/Modal/DepositRecoverModal'
import { queryKeys } from 'data/query-keys'
import { ethers } from 'ethers'
import { useTreasuryContract } from 'hooks/useTreasuryContract'
import { reportErrorToSentry } from 'lib/error-reporting'
import { api } from 'lib/query-client'
import { useAuth } from 'providers/AuthProvider'
import { useGlobal } from 'providers/GlobalProvider'
import { useMemo, useReducer } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { InitializeDepositInput, RegisterCardInput } from 'types/api'
import { CardType } from 'types/card'
import { DepositFees } from 'types/deposit'
import { getShortString } from 'utils/formatters'
import { useBalance } from 'wagmi'

interface ActivationCardApplyFormProps {
  selectedCardType: CardType
  showAgencyCodeField: boolean
  agencyCode?: string
}

const initialApplicationFormState = {
  name: '',
  surname: '',
  email: '',
  phoneAlphaCode: 'ARE',
  phoneNumber: '',

  country: '',
  street: '',
  buildingNumber: '',
  flatNumber: '',
  town: '',
  state: '',
  postcode: '',

  infoConfirmed: false,
  couponCode: '',
  discountValue: 0,
  isDiscountApplying: false,
  loadingText: '',
  reference: '',
  hasError: false,
  agencyCode: '',
}

type ApplicationFormState = typeof initialApplicationFormState

type ApplicationFormAction =
  | {
      type: 'SET_FIELD'
      field: keyof ApplicationFormState
      value: string | boolean | number
    }
  | {
      type: 'SET_MULTIPLE_FIELDS'
      fieldValuePairs: Partial<ApplicationFormState>
    }

type ApplicationFormReducer = React.Reducer<ApplicationFormState, ApplicationFormAction>

const applicationFormReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value }
    case 'SET_MULTIPLE_FIELDS':
      return { ...state, ...action.fieldValuePairs }
    default:
      return state
  }
}

export default function ActivationCardApplyForm({
  selectedCardType,
  showAgencyCodeField,
  agencyCode,
}: ActivationCardApplyFormProps) {
  const toast = useToast()
  const queryClient = useQueryClient()

  const [isLoading, { on: turnOnLoading, off: turnOffLoading }] = useBoolean()
  const {
    isOpen: isRecoverModalOpen,
    onOpen: onRecoverModalOpen,
    onClose: onRecoverModalClose,
  } = useDisclosure()

  const { user, account } = useAuth()
  const { countries, currentChain } = useGlobal()

  const navigate = useNavigate()
  const location = useLocation()

  const token = currentChain?.tokens?.[0]

  const [formData, dispatch] = useReducer<ApplicationFormReducer, ApplicationFormState>(
    applicationFormReducer,
    initialApplicationFormState,
    state => {
      const phoneAlphaCode =
        countries?.find(country => country.phoneCode === user?.phoneCode)?.alpha3 || 'ARE'
      return {
        ...state,
        phoneAlphaCode,
        name: user?.name ?? '',
        surname: user?.surname ?? '',
        email: user?.email ?? '',
        phoneNumber: user?.phoneNumber ?? '',
        country: user?.country ?? '',
        street: user?.street ?? '',
        buildingNumber: user?.buildingNumber ?? '',
        flatNumber: user?.flatNumber ?? '',
        town: user?.town ?? '',
        state: user?.state ?? '',
        postcode: user?.postcode ?? '',
        agencyCode: agencyCode ?? '',
      }
    },
  )

  const { deposit } = useTreasuryContract({
    tokenAddress: token?.address,
    treasuryAddress: currentChain?.treasuryAddress,
  })

  const { data: balanceData } = useBalance({
    enabled: token && account ? true : false,
    address: account,
    token: token?.address,
  })

  const availableBalance = useMemo(() => {
    if (!balanceData) {
      return undefined
    }

    return parseFloat(ethers.formatUnits(balanceData.value, token?.decimals))
  }, [balanceData, token])

  const { data: depositFees } = useQuery<DepositFees>({
    queryKey: [`deposits/${selectedCardType?.id}/fees`],
    enabled: !!selectedCardType,
    staleTime: 10 * 60 * 1000,
  })

  const { mutateAsync: initialiseDeposit } = useMutation({
    mutationKey: ['initialize-deposit'],
    mutationFn: async (data: InitializeDepositInput) =>
      api
        .post('cards/deposit/initialize', {
          json: data,
        })
        .json<{
          totalAmount: string
          reference: string
        }>(),

    onError: error => {
      toast({
        title: 'Error',
        description: error.message ?? 'Something went wrong. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const { mutateAsync: registerCard } = useMutation({
    mutationKey: ['register-card'],
    mutationFn: async (data: RegisterCardInput) =>
      api.post('cards/register', { json: data }).json(),
  })

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = e.target
    dispatch({ type: 'SET_FIELD', field: name as keyof ApplicationFormState, value })
    if (name === 'couponCode') {
      dispatch({ type: 'SET_FIELD', field: 'discountValue', value: 0 })
    }
  }

  const applyDiscount = async () => {
    if (!formData.couponCode) {
      return toast({ status: 'warning', title: 'Please enter a coupon code' })
    }

    if (!formData.agencyCode && showAgencyCodeField) {
      return toast({ status: 'warning', title: 'Please enter an agency code' })
    }

    try {
      dispatch({ type: 'SET_FIELD', field: 'isDiscountApplying', value: true })
      const urlQueryParams = new URLSearchParams({
        code: formData.couponCode,
        agencyCode: formData.agencyCode,
        cardTypeId: selectedCardType.id,
      }).toString()

      const { value: couponValue } = await queryClient.fetchQuery<{ value: number }>({
        queryKey: [
          `discounts/validate?${urlQueryParams}`,
          formData.couponCode,
          formData.agencyCode,
          selectedCardType.id,
        ],
        retry: false,
      })

      dispatch({
        type: 'SET_MULTIPLE_FIELDS',
        fieldValuePairs: {
          discountValue: couponValue,
          isDiscountApplying: false,
        },
      })
    } catch (err) {
      console.log(err)
      dispatch({ type: 'SET_FIELD', field: 'isDiscountApplying', value: false })
      toast({ status: 'error', title: 'Invalid coupon code' })
    }
  }

  const removeDiscount = () => {
    dispatch({
      type: 'SET_MULTIPLE_FIELDS',
      fieldValuePairs: {
        discountValue: 0,
        couponCode: '',
      },
    })
  }

  const applicationFee = useMemo(() => {
    if (!depositFees) {
      return 0
    }

    return (
      depositFees.applicationFee -
      depositFees.applicationFee * ((formData.discountValue ?? 0) / 100)
    )
  }, [depositFees, formData.discountValue])

  const amountToTransfer = useMemo(() => {
    return parseFloat(
      ethers.formatUnits(
        ethers.parseUnits(applicationFee.toString(), token?.decimals),
        token?.decimals,
      ),
    )
  }, [token, applicationFee])

  const hasSufficientBalance = useMemo(() => {
    if (availableBalance === undefined) {
      return false
    }

    return availableBalance >= amountToTransfer
  }, [availableBalance, amountToTransfer])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (
      !formData.name ||
      !formData.surname ||
      !formData.country ||
      !formData.email ||
      !formData.phoneAlphaCode ||
      !formData.phoneNumber ||
      !formData.street ||
      !formData.buildingNumber ||
      !formData.town ||
      !formData.state ||
      !formData.postcode ||
      !token?.id
    ) {
      toast({ status: 'error', title: 'Please fill all required fields' })
      return null
    }

    if (showAgencyCodeField && !formData.agencyCode) {
      toast({ status: 'error', title: 'Please enter agency code' })
      return null
    }

    if (!formData.infoConfirmed) {
      toast({ status: 'error', title: 'Please confirm your personal information' })
      return null
    }

    if (!hasSufficientBalance) {
      return toast({ status: 'error', title: 'Insufficient balance' })
    }

    try {
      turnOnLoading()
      dispatch({
        type: 'SET_FIELD',
        field: 'loadingText',
        value: `Requesting deposit of ${amountToTransfer}`,
      })
      dispatch({ type: 'SET_FIELD', field: 'hasError', value: false })

      if (showAgencyCodeField) {
        const { isValid } = await queryClient.fetchQuery<{ isValid: boolean }>({
          queryKey: [`agency/validate?code=${formData.agencyCode}`, formData.agencyCode],
          retry: false,
        })

        if (!isValid) {
          toast({ status: 'error', title: 'Invalid agency code' })
          turnOffLoading()
          return
        }
      }

      const { reference, totalAmount } = await initialiseDeposit({
        amount: amountToTransfer.toString(10),
        tokenId: token?.id,
        cardTypeId: selectedCardType?.id,
        discountCode: formData.couponCode,
        type: 'registration',
        agencyCode: formData.agencyCode,
      })
      dispatch({ type: 'SET_FIELD', field: 'loadingText', value: `Depositing ${amountToTransfer}` })
      dispatch({ type: 'SET_FIELD', field: 'reference', value: reference })
      await deposit(totalAmount as any, reference)
      try {
        dispatch({ type: 'SET_FIELD', field: 'loadingText', value: `Confirming transaction` })
        const phoneCode = countries?.find(
          country => country.alpha3 === formData.phoneAlphaCode,
        )?.phoneCode

        await registerCard({
          cardTypeId: selectedCardType?.id,
          reference: reference,
          name: formData.name,
          surname: formData.surname,
          email: formData.email,
          phoneCode: phoneCode?.toString() ?? '',
          phoneNumber: formData.phoneNumber,
          country: formData.country,
          town: formData.town,
          buildingNumber: formData.buildingNumber,
          street: formData.street,
          postcode: formData.postcode,
          flatNumber: formData.flatNumber,
          state: formData.state,
          agencyCode: formData.agencyCode,
        })

        await queryClient.refetchQueries({ queryKey: queryKeys.GET_CARDS })

        if (location.pathname === '/dashboard') window.location.reload()
        else navigate('/dashboard', { replace: true })
      } catch (error) {
        console.log(error)
        toast({ status: 'error', title: 'Failed to confirm transaction' })
        turnOffLoading()
        dispatch({ type: 'SET_FIELD', field: 'loadingText', value: '' })
        dispatch({ type: 'SET_FIELD', field: 'hasError', value: true })
        reportErrorToSentry(error)
      }
      turnOffLoading()
    } catch (error) {
      console.log(error)
      reportErrorToSentry(error)
      turnOffLoading()
    } finally {
      dispatch({ type: 'SET_FIELD', field: 'loadingText', value: '' })
    }
  }

  const handleRecoverDeposit = () => {
    onRecoverModalOpen()
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormControl isRequired>
        <FormLabel fontSize={14}>First Name</FormLabel>
        <Input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter your name"
          borderColor="#53545B"
          isDisabled={isLoading}
        />
        <FormHelperText>Must match the name(s) in the identity document</FormHelperText>
      </FormControl>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>Last Name</FormLabel>
        <Input
          type="text"
          name="surname"
          value={formData.surname}
          onChange={handleChange}
          placeholder="Enter your last name"
          borderColor="#53545B"
          isDisabled={isLoading}
        />
        <FormHelperText>Must match the surname in the identity document</FormHelperText>
      </FormControl>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>Email</FormLabel>
        <Input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter your email"
          borderColor="#53545B"
          isDisabled={isLoading}
        />
      </FormControl>
      <FormControl isRequired mt={6}>
        <FormLabel fontSize={14}>Phone Number</FormLabel>

        <Flex gap={2} border="1px solid #53545B" borderRadius={12}>
          <CountryCodeSelect
            options={countries ?? []}
            value={formData.phoneAlphaCode}
            isDisabled={isLoading}
            onChange={phoneAlphaCode =>
              dispatch({ type: 'SET_FIELD', field: 'phoneAlphaCode', value: phoneAlphaCode })
            }
          />
          <Input
            type="number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Enter your phone number"
            border="none"
            pl={0}
            isDisabled={isLoading}
          />
        </Flex>
      </FormControl>

      {showAgencyCodeField && (
        <FormControl isRequired mt={4}>
          <FormLabel fontSize={14}>Agency Code</FormLabel>
          <Input
            type="text"
            name="agencyCode"
            value={formData.agencyCode}
            onChange={handleChange}
            placeholder="Enter agency code"
            borderColor="#53545B"
            isDisabled={isLoading}
          />
        </FormControl>
      )}

      <FormLabel mt={4} fontSize={18} fontWeight={700}>
        Address
      </FormLabel>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>Country</FormLabel>
        <CountrySelect
          onSelect={countryCode =>
            dispatch({ type: 'SET_FIELD', field: 'country', value: countryCode })
          }
          selectProps={{
            borderColor: '#53545B',
            value: formData.country,
            isDisabled: isLoading,
          }}
        />
      </FormControl>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>Street</FormLabel>
        <Input
          type="text"
          name="street"
          value={formData.street}
          onChange={handleChange}
          placeholder="Enter your street"
          borderColor="#53545B"
          isDisabled={isLoading}
        />
      </FormControl>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>Building Number</FormLabel>
        <Input
          type="text"
          name="buildingNumber"
          value={formData.buildingNumber}
          onChange={handleChange}
          placeholder="Enter your building number"
          borderColor="#53545B"
          isDisabled={isLoading}
        />
      </FormControl>

      <FormControl mt={4}>
        <FormLabel fontSize={14}>Flat Number</FormLabel>
        <Input
          type="text"
          name="flatNumber"
          value={formData.flatNumber}
          onChange={handleChange}
          placeholder="Enter your flat number"
          borderColor="#53545B"
          isDisabled={isLoading}
        />
      </FormControl>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>Town/City</FormLabel>
        <Input
          type="text"
          name="town"
          value={formData.town}
          onChange={handleChange}
          placeholder="Enter your town/city"
          borderColor="#53545B"
          isDisabled={isLoading}
        />
      </FormControl>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>State</FormLabel>
        <Input
          type="text"
          name="state"
          value={formData.state}
          onChange={handleChange}
          placeholder="Enter your state"
          borderColor="#53545B"
          isDisabled={isLoading}
        />
      </FormControl>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>Postal Code</FormLabel>
        <Input
          type="text"
          name="postcode"
          value={formData.postcode}
          onChange={handleChange}
          placeholder="Enter your postal code"
          borderColor="#53545B"
          isDisabled={isLoading}
        />
      </FormControl>

      <Flex gap={6} mt={6}>
        <Input
          borderRadius={12}
          borderColor="#53545B"
          placeholder="Enter Coupon Code"
          name="couponCode"
          value={formData.couponCode}
          onChange={handleChange}
          isDisabled={isLoading}
        />
        <Button
          textColor={formData.discountValue ? 'red.500' : '#A22BFF'}
          fontWeight={700}
          bgColor="transparent"
          _hover={{ backgroundColor: 'transparent' }}
          isLoading={formData.isDiscountApplying}
          onClick={formData.discountValue ? removeDiscount : applyDiscount}
          isDisabled={isLoading || formData.isDiscountApplying}
        >
          {formData.discountValue ? 'Remove Discount' : 'Apply Discount'}
        </Button>
      </Flex>

      <Box w="full" mt={6}>
        <Grid gridTemplateColumns={'2fr 2fr'}>
          <Text fontSize={18} fontWeight={700}>
            Fee
          </Text>
          <Text textAlign="right">
            {applicationFee} {token?.symbol}
          </Text>
        </Grid>

        <Grid gridTemplateColumns={'2fr 2fr'} mt={4}>
          <Text fontSize={18} fontWeight={700}>
            Amount to transfer
          </Text>
          <Text textAlign="right">
            {amountToTransfer} {token?.symbol}
          </Text>
        </Grid>
      </Box>

      {formData.hasError && (
        <>
          <Box bg="red.600" borderRadius="xl" p={4} mt={4}>
            <Box fontSize="lg">An error has occurred</Box>
            <Box mt={3}>
              Something went wrong while confirming transaction. Please save the following deposit
              reference and use it to recover your deposit.
            </Box>
            <Box fontWeight="bold" mt={4}>
              Deposit reference:{' '}
              <CopyButton
                text={getShortString(formData.reference, 6)}
                value={formData.reference ?? ''}
              />
            </Box>
          </Box>
        </>
      )}

      {/* <Button
        p={0}
        fontWeight={700}
        onClick={handleRecoverDeposit}
        mt={7}
        h="unset"
        bg="transparent"
        _hover={{ bg: 'transparent' }}
      >
        <Text bgGradient="linear-gradient(180deg, #2649FF 0%, #8A16FF 100%)" bgClip="text">
          Recover Deposit
        </Text>
      </Button> */}

      <Checkbox
        size="lg"
        colorScheme="purple"
        mt={6}
        isChecked={formData.infoConfirmed}
        isDisabled={isLoading}
        onChange={e =>
          dispatch({ type: 'SET_FIELD', field: 'infoConfirmed', value: e.target.checked })
        }
      >
        <Box fontSize="sm" ml={2}>
          I confirm that provided personal information (including email and phone number) is valid
          and relevant
        </Box>
      </Checkbox>

      <NorpayButton
        w="full"
        mt={6}
        isDisabled={isLoading}
        bgGradient="linear-gradient(159deg, #5317FF 14.06%, #591ED2 94.29%)"
        isLoading={isLoading}
        loadingText={formData.loadingText}
        type="submit"
        py={4}
        maxW="unset"
      >
        Get Your Card
      </NorpayButton>

      {selectedCardType.id && (
        <DepositRecoverModal
          isOpen={isRecoverModalOpen}
          onClose={onRecoverModalClose}
          cardTypeId={selectedCardType.id}
        />
      )}
    </form>
  )
}
