import type { SVGProps } from 'react'
const SvgBinanceCoin = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={53} height={48} fill="none" {...props}>
    <path
      fill="#AD4800"
      d="M15.948 9.342 4.342 22.008c1.233 2.174 2.826 4.45 4.725 6.722l13.99-15.267c-2.43-1.694-4.834-3.08-7.11-4.12"
    />
    <path
      fill="#AF5C00"
      d="M23.056 13.462 9.066 28.73a61 61 0 0 0 5.564 5.806L29.325 18.5a61 61 0 0 0-6.269-5.038"
    />
    <path
      fill="#AD2B00"
      d="M7.364 3.731 2.685 8.837c-1.022 1.117-1.447 2.683-1.34 4.559l7.561-8.252z"
    />
    <path
      fill="#AD3E00"
      d="M11.779 7.778 8.905 5.144l-7.562 8.252c.14 2.448 1.188 5.423 2.997 8.61L15.946 9.338a29 29 0 0 0-4.167-1.561"
    />
    <path
      fill="#FFCB00"
      d="M39.615 31.03 28.01 43.695c3.331 1.524 6.386 2.309 8.837 2.235l7.56-8.25-2.874-2.635a29 29 0 0 0-1.918-4.015"
    />
    <path
      fill="#FFD500"
      d="m41.27 44.197 4.68-5.107-1.542-1.415-7.561 8.255c1.877-.057 3.405-.616 4.424-1.733"
    />
    <path
      fill="#E08700"
      d="M14.63 34.536a61 61 0 0 0 6.27 5.037l13.99-15.268a61 61 0 0 0-5.565-5.806z"
    />
    <path
      fill="orange"
      d="M34.89 24.306 20.9 39.573c2.43 1.694 4.833 3.08 7.109 4.121L39.614 31.03c-1.233-2.176-2.825-4.45-4.725-6.724"
    />
    <path
      fill="url(#binance-coin_svg__a)"
      d="M45.95 39.089c4.057-4.428-1.292-15.933-11.947-25.696C23.347 3.628 11.42-.698 7.363 3.731 3.304 8.16 8.652 19.665 19.308 29.428s22.583 14.089 26.64 9.66"
    />
    <path
      fill="url(#binance-coin_svg__b)"
      d="M44.4 37.47c3.614-3.945-1.356-14.38-11.1-23.31S12.724 1.19 9.11 5.135c-3.614 3.945 1.356 14.38 11.1 23.31 9.746 8.929 20.575 12.97 24.19 9.025"
    />
    <path
      fill="url(#binance-coin_svg__c)"
      d="M43.211 37.573c3.374-3.683-1.524-13.662-10.94-22.29S12.487 2.647 9.113 6.33s1.524 13.661 10.94 22.289 19.784 12.637 23.158 8.955"
    />
    <path
      fill="#FFF700"
      d="M45.95 39.09c2.422-2.645 1.493-7.805-1.914-13.613l-1.551-.344-1.04 1.136-27.75-2.71a61 61 0 0 0 5.619 5.872c10.65 9.763 22.578 14.088 26.636 9.66"
      style={{
        mixBlendMode: 'soft-light',
      }}
    />
    <path
      fill="#fff"
      d="M31.842 25.502c1.021.16 1.745-.055 2.18-.636.494-.666.487-1.606-.005-2.816q-.583-1.428-1.814-2.733l.625-.735-1.661-1.52-.625.69-.66-.604-1.431-1.313.65-.665-1.658-1.518-.674.645-1.006-.922-1.49-1.365-1.368 1.197 1.527 1.4-.771.71-4.42 4.075-1.672-1.532-1.72 1.5-.014.01 1.72 1.576 1.16 1.064-.923.884 1.97 1.803.894-.913 2.41 2.214-.853.946 1.969 1.803.838-.985c1.257 1.043 2.535 1.706 3.818 1.986 1.435.313 2.444.054 3.035-.758.606-.841.59-2.004-.031-3.488m-4.664-8.378.004.005 2.143 1.967 1.013.928q.362.326.638.73.168.25.281.53.268.67-.027 1.03c-.197.24-.52.291-.976.165a2.7 2.7 0 0 1-.556-.224 4 4 0 0 1-.808-.583l-1.039-.952-2.207-2.023h-.006zm.51 9.425a3.4 3.4 0 0 1-1.174-.551 5 5 0 0 1-.52-.42l-1.392-1.276-2.347-2.15-.005-.006 1.974-2.012.006.005 2.265 2.076 1.269 1.163a10 10 0 0 1 .788.814q.957 1.353.364 2.087-.377.47-1.227.27"
    />
    <defs>
      <linearGradient
        id="binance-coin_svg__a"
        x1={7.497}
        x2={44.186}
        y1={3.858}
        y2={37.476}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC500" />
        <stop offset={1} stopColor="#FFCF00" />
      </linearGradient>
      <linearGradient
        id="binance-coin_svg__b"
        x1={7.725}
        x2={38.253}
        y1={3.869}
        y2={31.84}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE000" />
        <stop offset={1} stopColor="#AD4B00" />
      </linearGradient>
      <linearGradient
        id="binance-coin_svg__c"
        x1={9.401}
        x2={33.837}
        y1={6.595}
        y2={28.986}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9C00" />
        <stop offset={0.54} stopColor="#FFCD00" />
        <stop offset={1} stopColor="#FFE700" />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgBinanceCoin
