import Discounts from './components/Discounts'
import Statistics from './components/Statistics'
import UserSearch from './components/UserSearch'
import { useAuth } from 'providers/AuthProvider'
import { Navigate } from 'react-router-dom'
import Agency from './components/Agency'

const AdminPanel = () => {
  const { user } = useAuth()

  if (user?.role !== 'admin') {
    return <Navigate to="/" />
  }

  return (
    <>
      <Statistics />
      <UserSearch />
      <Discounts />
      <Agency />
    </>
  )
}

export default AdminPanel
