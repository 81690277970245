import type { SVGProps } from 'react'
const SvgBnbSmartChain = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={42} height={42} fill="none" {...props}>
    <g clipPath="url(#bnb-smart-chain_svg__a)">
      <path
        fill="#F3BA2F"
        d="M20.714 41.167c11.331 0 20.517-9.186 20.517-20.517S32.045.133 20.714.133.197 9.319.197 20.65s9.186 20.517 20.517 20.517"
      />
      <path
        fill="#fff"
        d="m15.733 18.605 4.98-4.98 4.984 4.982 2.898-2.898-7.881-7.88-7.879 7.878zM7.891 20.65l2.898-2.898 2.898 2.898-2.898 2.898zm7.842 2.047 4.98 4.98 4.984-4.983 2.898 2.897-7.881 7.882-7.879-7.878-.004-.004zm12.008-2.047 2.898-2.898 2.898 2.898-2.898 2.898zm-4.088-.002h.002v.002l-2.941 2.942-2.938-2.937-.005-.005.005-.004.514-.515.25-.25 2.174-2.172z"
      />
    </g>
    <defs>
      <clipPath id="bnb-smart-chain_svg__a">
        <path fill="#fff" d="M.2.137h41.033V41.17H.2z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgBnbSmartChain
