import { Box, Flex, Image, Text } from '@chakra-ui/react'
import Step1Image from 'assets/step-1.png'
import Step2Image from 'assets/step-2.png'
import Step3Image from 'assets/step-3.png'
import { AuthenticateButton } from 'components'

const steps = [
  {
    image: Step1Image,
    description: 'Connect Wallet',
    gradient:
      'radial-gradient(50% 50% at 50% 50%, rgba(255, 46, 0, 0.56) 3.9%, rgba(255, 206, 80, 0.56) 100%)',
  },
  {
    image: Step2Image,
    description: 'Select Card',
    gradient:
      'radial-gradient(50% 50% at 50% 50%, rgba(255, 0, 122, 0.41) 26.9%, rgba(54, 50, 255, 0.41) 100%)',
  },
  {
    image: Step3Image,
    description: 'Start Spending',
    gradient:
      'radial-gradient(50% 50% at 50% 50%, rgba(66, 0, 255, 0.27) 4.4%, rgba(255, 0, 122, 0.27) 100%)',
  },
]

export default function StepsToGetNorpayCard() {
  return (
    <Box as="section" mt={12}>
      <Text
        fontSize={26}
        fontWeight={700}
        lineHeight={10}
        textAlign="center"
        style={{ textWrap: 'balance' }}
      >
        Get Your Norpay Card In{' '}
        <Text
          as="span"
          bgGradient="linear-gradient(180deg, #FF269B 57.89%, #FFDC26 100%)"
          bgClip="text"
        >
          3 Simple Steps
        </Text>
      </Text>

      {steps.map((step, index) => (
        <Flex
          bgGradient="linear-gradient(0deg, #080808 0%, rgba(18, 18, 18, 0.00) 92.43%)"
          borderRadius={12}
          flexDir="column"
          alignItems="center"
          justify="center"
          mt={10}
          paddingInline={9}
          paddingBlock={12}
          key={step.description}
          w="fit-content"
          marginInline="auto"
        >
          <Text color="#EDF6FF" fontSize={24} lineHeight={10} mb={{ base: 10, md: 10 }}>
            Step {index + 1}
          </Text>

          <Box pos="relative">
            <Image src={step.image} w="100%" height="100%" />
            <Box
              w="280px"
              height="280px"
              bgGradient={step.gradient}
              borderRadius="50%"
              filter="blur(50px)"
              pos="absolute"
              left="50%"
              transform="translateX(-50%)"
              top={'-24px'}
              zIndex={-1}
            />
          </Box>

          <Text fontSize={28} lineHeight={10} fontWeight={700} mt={5}>
            {step.description}
          </Text>

          <Text color="#989898" textAlign="center" style={{ textWrap: 'balance' }} mt={5}>
            Click On Connect Wallet & Connect Your MetaMask Wallet
          </Text>

          <Flex justify="center" mt={8} w="100%">
            <AuthenticateButton />
          </Flex>
        </Flex>
      ))}
    </Box>
  )
}
