import { Box, Flex, Image, Link } from '@chakra-ui/react'
import { Container } from 'components'

export default function Footer() {
  return (
    <Container mt={32} mb={12}>
      <Flex direction="column" align="center" justify="center" textColor="whiteAlpha.600">
        <Image src="/img/logo.svg" />
        <Flex mt={6} gap={{ base: 6, md: 12 }}>
          <Link href="https://www.norpay.io/terms-and-conditions">Legal</Link>
          <Link href="https://www.norpay.io/blog/how-to-apply-for-the-norpay-crypto-debit-card">
            Steps To Apply
          </Link>
        </Flex>
        <Box mt={6}>2024 Norpay | All rights reserved</Box>
        <Box mt={6}>
          <span>For any further queries, please email us at </span>
          <Link href="mailto: support@norpay.io">support@norpay.io</Link>
        </Box>
      </Flex>
    </Container>
  )
}
