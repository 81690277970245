import { CardName } from 'types/card'

export const cardIssuerType = {
  [CardName.PhysicalCard]: 'MasterCard (Virtual)',
  [CardName.VirtualCard]: 'VISA (Virtual)',
  [CardName.NorpayEURCard]: 'MasterCard (Virtual)',
  [CardName.VirtualApplePay]: 'VISA (Virtual)',
  [CardName.MeshCard]: 'VISA (Virtual)',
}

export const cardTypeToGradient = {
  [CardName.VirtualCard]: 'linear-gradient(180deg, #280904 0%, #040209 59.27%, #020003 100%)',
  [CardName.PhysicalCard]: 'linear-gradient(179deg, #2A2A2A -5.54%, #000 89.19%)',
  [CardName.NorpayEURCard]: 'linear-gradient(179deg, #2A2A2A -5.54%, #000 89.19%)',
}

export const cardTypeToDisplayName = {
  [CardName.VirtualCard]: 'Norpay Virtual Card',
  [CardName.PhysicalCard]: 'Norpay Physical Card',
  [CardName.NorpayEURCard]: 'Norpay Virtual (EUR) Card',
  [CardName.VirtualApplePay]: 'Virtual Apple Pay Card',
  [CardName.MeshCard]: 'Virtual Apple/Google Pay Card',
}
