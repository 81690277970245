import { Box, Flex, Image, chakra } from '@chakra-ui/react'
import { ConnectButton, Container } from 'components'
import { useAuth } from 'providers/AuthProvider'
import { Link, useLocation } from 'react-router-dom'

const ChakraLink = chakra(Link)

const Navbar = () => {
  const { isAuthenticated, user } = useAuth()
  const location = useLocation()

  return (
    <Box bgColor="#0B0B0B" borderBottom="1px solid #67676718">
      <Container
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        py="12px"
        px={{ base: 1, md: 4 }}
      >
        <Link to={isAuthenticated ? '/dashboard' : '/'}>
          <Image src="/img/logo.svg" h={{ base: '24px', md: '32px' }} maxW="fit-content" />
        </Link>
        <Flex justifyContent="end" alignItems="center" gap={{ base: 1, md: 3 }}>
          {isAuthenticated &&
          user &&
          user.role === 'admin' &&
          location.pathname === '/dashboard' ? (
            <ChakraLink
              to="/admin"
              bg={'linear-gradient(135deg, #7C2FDD 0%, #88A0F0 100%)'}
              color="white"
              fontSize={{ base: '10px', sm: '12px', md: '16px' }}
              px={{ base: '4px', sm: '8px', md: '16px' }}
              rounded={'md'}
              py={{ base: '8px', md: '12px' }}
            >
              Admin Panel
            </ChakraLink>
          ) : (
            <></>
          )}
          <ConnectButton />
        </Flex>
      </Container>
    </Box>
  )
}

export default Navbar
