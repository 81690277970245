import {
  Box,
  Checkbox,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { ConnectButton as RainbowConnectButton } from '@rainbow-me/rainbowkit'
import SvgWalletConnected from 'assets/WalletConnected'
import NorpayButton from 'components/Button/NorpayButton'

interface ConnectedModalPros {
  isWalletConnectedModalOpen: boolean
  closeWalletConnectedModal: () => void
}

export default function ConnectedModal({
  isWalletConnectedModalOpen,
  closeWalletConnectedModal,
}: ConnectedModalPros) {
  return (
    <Modal
      onClose={closeWalletConnectedModal}
      size="sm"
      isOpen={isWalletConnectedModalOpen}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        background="linear-gradient(135deg, #090909 12.06%, #1E1E1E 41.81%, #0B0B0B 70.59%, #212121 103.3%, #070707 121.42%)"
        borderRadius={6}
        borderColor="rgba(138, 138, 138, 0.18)"
        borderWidth={1}
      >
        <ModalCloseButton
          bgColor="white"
          borderRadius="100%"
          sx={{
            '> svg': {
              color: 'black',
            },
          }}
        />
        <ModalBody display="flex" flexDir="column" alignItems="center" mt={7}>
          <Text fontSize={26} fontWeight={700} lineHeight="40px">
            Wallet Connected
          </Text>

          <SvgWalletConnected />

          <RainbowConnectButton.Custom>
            {({ mounted, account: connectedAccount, chain, authenticationStatus }) => {
              const ready = mounted && authenticationStatus !== 'loading'
              const connected =
                ready &&
                connectedAccount &&
                chain &&
                (!authenticationStatus || authenticationStatus === 'authenticated')
              if (!connected) return null
              return (
                <>
                  <Box
                    as="section"
                    aria-label="wallet-address"
                    py={2}
                    px={4}
                    bgColor="blackAlpha.500"
                    display="flex"
                    flexDir="column"
                    borderRadius={12}
                    w="100%"
                  >
                    <Text fontSize={18} fontWeight={700} color="#FCFCFC" lineHeight={6}>
                      Wallet Address
                    </Text>
                    <Flex gap={2} mt={4} alignItems="baseline">
                      <Checkbox
                        isChecked
                        sx={{
                          '> span[data-checked]': {
                            background: '#4A04FF',
                            borderColor: '#4A04FF',
                          },
                          '> span[data-checked][data-hover]': {
                            background: '#4A04FF',
                            borderColor: '#4A04FF',
                          },
                        }}
                      />
                      <Text fontWeight={500} lineHeight={5} wordBreak="break-word">
                        {connectedAccount?.address}
                      </Text>
                    </Flex>
                  </Box>

                  <Box
                    as="section"
                    aria-label="chain"
                    py={2}
                    px={4}
                    bgColor="blackAlpha.500"
                    display="flex"
                    flexDir="column"
                    borderRadius={12}
                    w="100%"
                    mt={3}
                  >
                    <Text fontSize={18} fontWeight={700} color="#FCFCFC" lineHeight={6}>
                      Chain
                    </Text>
                    <Flex gap={2} mt={4} alignItems="center">
                      {chain?.hasIcon && (
                        <Box
                          background={chain?.iconBackground}
                          height="18px"
                          width="18px"
                          borderRadius="xl"
                          overflow="hidden"
                        >
                          {chain.iconUrl && <Image src={chain.iconUrl} w="18px" h="18px" />}
                        </Box>
                      )}
                      <Text fontSize={18} fontWeight={500} lineHeight={6}>
                        {chain?.name}
                      </Text>
                    </Flex>
                  </Box>
                </>
              )
            }}
          </RainbowConnectButton.Custom>
        </ModalBody>

        <ModalFooter display="flex" flexDir="column">
          <NorpayButton
            bgGradient="linear-gradient(159deg, #5317FF 14.06%, #591ED2 94.29%)"
            fontSize={18}
            fontWeight={600}
            fontFamily="Inter Variable"
            py={4}
            onClick={closeWalletConnectedModal}
          >
            Continue
          </NorpayButton>
          <Text fontSize={12} mt={6}>
            Chain & Wallet can be accessed on top
          </Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
