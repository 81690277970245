import { CardName } from 'types/card'

export const getShortString = (value: string | null | undefined, n = 6) => {
  if (!value) {
    return ''
  }
  return `${value.substring(0, n)}...${value.substring(value.length - n, value.length)}`
}

export const cardNameToImagePath = (cardName: string) => {
  switch (cardName) {
    case 'Norpay Virtual (EUR)':
      return 'card-virtualEUR.svg'

    case 'Virtual Apple Pay':
      return 'card-applePay.svg'

    case 'Norpay Physical':
      return 'card-physical.png'

    case 'Virtual':
      return 'card-virtual.svg'

    default:
      return 'card-virtual.svg'
  }
}

export const getCardImage = (cardName: CardName) => {
  switch (cardName) {
    case CardName.NorpayEURCard:
      return '/cards/norpay-virtual-EUR-card.png'

    case CardName.VirtualCard:
      return '/cards/norpay-virtual-card.png'

    case CardName.PhysicalCard:
      return '/cards/norpay-physical-card.png'

    case CardName.VirtualApplePay:
      return '/cards/card-applePay.svg'

    case CardName.MeshCard:
      return '/cards/norpay-mesh-card.png'

    default:
      return '/cards/norpay-virtual-card.png'
  }
}
