import { Button, ButtonProps } from '@chakra-ui/react'
import { motion } from 'framer-motion'

interface NorpayButtonProps extends Omit<ButtonProps, 'as'> {
  children: React.ReactNode
}

export default function NorpayButton({ children, ...buttonProps }: NorpayButtonProps) {
  return (
    <Button
      as={motion.button}
      whileTap={{ scale: 0.95 }}
      bgGradient="linear-gradient(159deg, #5317FF 14.06%, #8E1ED2 94.29%)"
      height="auto"
      _hover={{ bgGradient: 'linear-gradient(159deg, #5317FF 14.06%, #8E1ED2 94.29%)' }}
      filter="drop-shadow(4px 4px 0px #fff)"
      maxW={80}
      color="white"
      w="100%"
      opacity={buttonProps.disabled ? 0.5 : 1}
      isDisabled={buttonProps.disabled}
      {...buttonProps}
    >
      {children}
    </Button>
  )
}
