import { Box, Flex, Text, keyframes } from '@chakra-ui/react'
import SvgBinanceCoin from 'assets/BinanceCoin'
import SvgSparkles from 'assets/Sparkles'
import { AuthenticateButton, Container } from 'components'
import CardSlider from 'components/CardSlider/CardSlider'
import { useState } from 'react'
import { CardName } from 'types/card'
import StepsToGetNorpayCard from './components/StepsToGetNorpayCard'
import SupportedChains from './components/SupportedChains/SupportedChains'

const bounceKeyframe = keyframes({
  '0%': { transform: 'translateY(-25%)', animationTimingFunction: 'cubic-bezier(.8,0,1,1)' },
  '50%': { transform: 'none', animationTimingFunction: 'cubic-bezier(0,0,.2,1)' },
  '100%': { transform: 'translateY(-25%)', animationTimingFunction: 'cubic-bezier(.8,0,1,1)' },
})

const Home = () => {
  const [currentCard, setCurrentCard] = useState<CardName>(CardName.VirtualCard)

  return (
    <main>
      <Box
        as="section"
        aria-label="hero section"
        bgImage={{ md: '/img/home_bg.webp' }}
        backgroundPosition={{ md: 'bottom' }}
      >
        <Container maxW="80rem">
          <SvgBinanceCoin opacity={0.24} />

          <Box pt={12} />

          <CardSlider
            currentCard={currentCard}
            setCurrentCard={setCurrentCard}
            cardSliderSectionProps={{
              marginInline: 'auto',
              scale: { md: 1.2 },
              transition: 'scale 0.5s ease-in-out',
              transform: 'scaleX(var(--chakra-scale-x)) scaleY(var(--chakra-scale-y))',
            }}
          />

          <Flex as="section" mt="64px" justify="center">
            <Text
              as="h1"
              fontFamily="Nasalization"
              color="white"
              fontSize={{ base: 38, md: 74 }}
              fontWeight={400}
              lineHeight="50px"
              pos="relative"
              style={{
                WebkitTextStroke: '1.5px rgba(255, 255, 255, 0.61)',
              }}
            >
              Spend Crypto
              <Box as="span" top={-6} left={-10} pos="absolute">
                <SvgSparkles />
              </Box>
            </Text>
          </Flex>

          <Text
            textAlign="center"
            fontSize={{ base: 24, md: 40 }}
            fontWeight={500}
            lineHeight={{ base: 10, md: '66px' }}
          >
            Just like Traditional Cash
          </Text>

          <Text
            textAlign="center"
            color={{ base: '#ffffff81', md: 'white' }}
            fontFamily="Inter Variable"
            fontSize={{ base: 12, md: 20 }}
            lineHeight={5}
            maxW={{ base: '276px', md: '452px' }}
            marginInline={'auto'}
          >
            Experience 2024’s fastest & most secure way to spend your Crypto with Norpay Visa Cards.
          </Text>

          <Flex justify="center" mt={10}>
            <AuthenticateButton />
          </Flex>

          <Box mt="72px" />

          <SupportedChains />
        </Container>
      </Box>

      <Container maxW="80rem" marginInline="auto">
        <Box
          as="section"
          mt={14}
          display={{ base: 'flex', md: 'none' }}
          flexDir="column"
          alignItems="center"
        >
          <Text textAlign="center" fontSize={16} fontWeight={500} lineHeight={5}>
            3 Simple Steps To Get The Norpay Card
          </Text>
          <Box animation={`${bounceKeyframe} 1s infinite`} mt={3}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="30"
              viewBox="0 0 22 30"
              fill="none"
            >
              <path
                d="M12 17H10V16H9V15H8V14H7V13H6V11H8V12H9V13H10V4H12V13H13V12H14V11H16V13H15V14H14V15H13V16H12"
                fill="white"
              />
            </svg>
          </Box>
        </Box>

        <StepsToGetNorpayCard />
      </Container>
    </main>
  )
}

export default Home
