import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import { injectedWallet } from '@rainbow-me/rainbowkit/wallets'
import {
  BSC_RPC_PROVIDER,
  BSC_TESTNET_RPC_PROVIDER,
  POLYGON_MUMBAI_RPC_PROVIDER,
} from 'data/constants'
import { Chain, configureChains, createConfig } from 'wagmi'
import { bsc, bscTestnet, polygonMumbai } from 'wagmi/chains'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

const isProduction = process.env.REACT_APP_PRODUCTION !== 'false'

const availableChains: Chain[] = isProduction ? [bsc] : [polygonMumbai, bscTestnet]

const { chains, publicClient } = configureChains(availableChains, [
  jsonRpcProvider({
    rpc: chain => {
      switch (chain.id) {
        case polygonMumbai.id:
          return { http: POLYGON_MUMBAI_RPC_PROVIDER }
        case bscTestnet.id:
          return { http: BSC_TESTNET_RPC_PROVIDER }
        case bsc.id:
          return { http: BSC_RPC_PROVIDER }
        default:
          return null
      }
    },
  }),
])

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [injectedWallet({ chains })],
  },
])

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
})

declare module 'wagmi' {
  interface Register {
    config: typeof wagmiConfig
  }
}

export { chains, wagmiConfig }
