import type { SVGProps } from 'react'
const SvgEtheriumChain = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27} height={27} fill="none" {...props}>
    <rect
      width={26.182}
      height={26.182}
      x={0.041}
      y={0.063}
      fill="url(#etherium-chain_svg__a)"
      rx={13.091}
    />
    <g fill="#fff" clipPath="url(#etherium-chain_svg__b)">
      <path
        stroke="#fff"
        strokeWidth={0.227}
        d="M16.586 7.79a.11.11 0 0 1 .112.112v10.095a.11.11 0 0 1-.07.104.11.11 0 0 1-.123-.025l-2.152-2.151a.1.1 0 0 1-.033-.079V7.899a.113.113 0 0 1 .114-.11z"
      />
      <path
        stroke="#fff"
        strokeWidth={0.227}
        d="M9.564 11.087v-2.87a.113.113 0 0 1 .193-.079l6.909 6.908q.03.034.033.079v2.87a.11.11 0 0 1-.07.104.11.11 0 0 1-.123-.025l-6.908-6.907a.11.11 0 0 1-.034-.08Z"
      />
      <path d="M10.779 18.46a1.57 1.57 0 1 0 0-3.14 1.57 1.57 0 0 0 0 3.14" />
      <path d="M10.78 18.274a1.385 1.385 0 1 0 0-2.77 1.385 1.385 0 0 0 0 2.77M16.58 12.555h-2.148v2.588h2.147z" />
      <path d="M16.58 15.12v2.862h-.005l-2.143-2.143v-2.866z" />
    </g>
    <defs>
      <linearGradient
        id="etherium-chain_svg__a"
        x1={6.763}
        x2={16.316}
        y1={0.063}
        y2={26.244}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.307} stopColor="#8B6CFE" />
        <stop offset={0.599} stopColor="#6A62FD" />
        <stop offset={1} stopColor="#78CAE7" />
      </linearGradient>
      <clipPath id="etherium-chain_svg__b">
        <path fill="#fff" d="M9.209 7.668h7.599V18.46H9.209z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgEtheriumChain
