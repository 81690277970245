import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
  useBoolean,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import CopyButton from 'components/Button/CopyButton'
import NorpayButton from 'components/Button/NorpayButton'
import { queryKeys } from 'data/query-keys'
import { ethers } from 'ethers'
import { useTreasuryContract } from 'hooks/useTreasuryContract'
import { api } from 'lib/query-client'
import { useAuth } from 'providers/AuthProvider'
import { useGlobal } from 'providers/GlobalProvider'
import { Suspense, useMemo, useState, useTransition } from 'react'
import { ApplyCardInput, InitializeDepositInput, RegisterCardInput } from 'types/api'
import { CardName } from 'types/card'
import { DepositFees } from 'types/deposit'
import { getShortString } from 'utils/formatters'
import { useBalance } from 'wagmi'
import DepositRecoverModal from './DepositRecoverModal'
import { reportErrorToSentry } from 'lib/error-reporting'
import { useNavigate } from 'react-router-dom'

interface ApplyCardModalProps {
  isOpen: boolean
  onClose: () => void
  showDiscountSection?: boolean
  selectedCardName: CardName
  agencyCode: string
}

export default function ApplyCardModal({
  isOpen,
  onClose,
  selectedCardName,
  showDiscountSection = true,
  agencyCode,
}: ApplyCardModalProps) {
  const queryClient = useQueryClient()

  const { currentChain, cardTypes } = useGlobal()
  const { account, user } = useAuth()

  const toast = useToast()
  const [isLoading, { on: turnOnLoading, off: turnOffLoading }] = useBoolean()
  const {
    isOpen: isRecoverModalOpen,
    onOpen: onRecoverModalOpen,
    onClose: onRecoverModalClose,
  } = useDisclosure()

  const token = currentChain?.tokens?.[0]

  const selectedCardType = useMemo(() => {
    if (!cardTypes) {
      return undefined
    }

    return cardTypes.find(cardType => cardType.name === selectedCardName)
  }, [cardTypes, selectedCardName])

  const { deposit } = useTreasuryContract({
    tokenAddress: token?.address,
    treasuryAddress: currentChain?.treasuryAddress,
  })

  const { data: balanceData } = useBalance({
    enabled: token && account ? true : false,
    address: account,
    token: token?.address,
    watch: true,
    cacheTime: 2 * 60 * 1000,
    staleTime: 2 * 60 * 1000,
  })

  const { mutateAsync: initialiseDeposit } = useMutation({
    mutationKey: ['initialize-deposit'],
    mutationFn: async (data: InitializeDepositInput) =>
      api
        .post('cards/deposit/initialize', {
          json: data,
        })
        .json<{
          totalAmount: string
          reference: string
        }>(),
    onError: error => {
      toast({
        title: 'Error',
        description: error.message ?? 'Something went wrong. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const { mutateAsync: registerCard } = useMutation({
    mutationKey: ['register-card'],
    mutationFn: async (data: RegisterCardInput) =>
      api.post('cards/register', { json: data }).json(),
  })

  const { mutateAsync: applyCard } = useMutation({
    mutationKey: [`cards/apply/${selectedCardType?.id}`, selectedCardType?.id],
    mutationFn: ({ cardTypeId, ...data }: ApplyCardInput & { cardTypeId: string }) =>
      api.post(`cards/apply/${cardTypeId}`, { json: data }),
  })

  const navigate = useNavigate()

  const [, startTransition] = useTransition()

  const [couponCode, setCouponCode] = useState('')
  const [depositAmount, setDepositAmount] = useState('')
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [discountValue, setDiscountValue] = useState<number | undefined>()
  const [isDiscountApplying, setIsDiscountApplying] = useState(false)
  const [depositReference, setDepositReference] = useState<string | undefined>(undefined)
  const [hasError, setHasError] = useState(false)
  const [loadingText, setLoadingText] = useState('')

  const isActivationRequired = useMemo(
    () => selectedCardType?.applicationType === 'activation',
    [selectedCardType],
  )

  const { data: depositFees } = useQuery<DepositFees>({
    queryKey: [`deposits/${selectedCardType?.id}/fees`],
    enabled: !!selectedCardType,
    staleTime: 10 * 60 * 1000,
  })

  const availableBalance = useMemo(() => {
    if (!balanceData) {
      return undefined
    }

    return parseFloat(ethers.formatUnits(balanceData.value, token?.decimals))
  }, [balanceData, token])

  const applicationFee = useMemo(() => {
    if (!depositFees) {
      return 0
    }

    return depositFees.applicationFee - depositFees.applicationFee * ((discountValue ?? 0) / 100)
  }, [depositFees, discountValue])

  const { amount, amountInBigInt } = useMemo(() => {
    const parsedAmount =
      Math.floor(parseFloat(depositAmount === '' ? '0' : depositAmount) * 100) / 100

    return {
      amount: parsedAmount,
      amountInBigInt: ethers.parseUnits(`${parsedAmount}`, token?.decimals),
    }
  }, [depositAmount])

  const isPayingSufficientAmount = useMemo(() => {
    return amount >= (depositFees?.minApplicationDepositAmount ?? 0)
  }, [amount, depositFees])

  const amountToTransfer = useMemo(() => {
    const amountWithFeesBigInt =
      amountInBigInt + ethers.parseUnits(applicationFee.toString(), token?.decimals)
    return parseFloat(ethers.formatUnits(amountWithFeesBigInt, token?.decimals))
  }, [amount, token, applicationFee, amountInBigInt])

  const hasSufficientBalance = useMemo(() => {
    if (availableBalance === undefined) {
      return false
    }

    return availableBalance >= amountToTransfer
  }, [availableBalance, amountToTransfer])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'couponCode') {
      setDiscountValue(undefined)
      setCouponCode(e.target.value)
    }
  }

  const handleRecoverDeposit = () => {
    onRecoverModalOpen()
  }

  const closeModal = () => {
    startTransition(() => {
      onClose()
    })

    setCouponCode('')
    setDepositAmount('')
    setIsConfirmed(false)
    setDiscountValue(undefined)
  }

  const handleApplyCard = async () => {
    if (token?.id === undefined || selectedCardType?.id === undefined) return

    if (!isConfirmed) {
      return toast({
        status: 'warning',
        title: 'Please confirm that provided information is valid',
      })
    }

    if (!isPayingSufficientAmount) {
      return toast({
        status: 'error',
        title: `Minimum deposit amount is ${depositFees?.minApplicationDepositAmount}`,
      })
    }

    if (!hasSufficientBalance) {
      return toast({ status: 'error', title: 'Insufficient balance' })
    }

    try {
      turnOnLoading()
      startTransition(() => {
        setHasError(false)
        setLoadingText(`Requesting deposit of ${amountToTransfer}`)
      })

      const { reference, totalAmount } = await initialiseDeposit({
        amount: amountInBigInt.toString(10),
        tokenId: token?.id,
        cardTypeId: selectedCardType?.id,
        discountCode: couponCode,
        type: isActivationRequired ? 'registration' : 'undefined',
        agencyCode,
      })
      startTransition(() => {
        setDepositReference(reference)
        setLoadingText(`Waiting for transaction`)
      })
      await deposit(totalAmount as any, reference)
      try {
        startTransition(() => {
          setLoadingText(`Confirming transaction`)
        })
        if (isActivationRequired) {
          await registerCard({
            cardTypeId: selectedCardType?.id,
            reference: reference,
            name: user?.name ?? '',
            surname: user?.surname ?? '',
            email: user?.email ?? '',
            phoneCode: user?.phoneCode?.toString() ?? '',
            phoneNumber: user?.phoneNumber ?? '',
            country: user?.country ?? '',
            town: user?.town ?? '',
            buildingNumber: user?.buildingNumber ?? '',
            street: user?.street ?? '',
            postcode: user?.postcode ?? '',
            flatNumber: user?.flatNumber ?? '',
            state: user?.state ?? '',
            agencyCode: agencyCode,
          })
        } else {
          await applyCard({ reference: reference, cardTypeId: selectedCardType?.id, agencyCode })
        }
        await queryClient.refetchQueries({ queryKey: queryKeys.GET_CARDS })
        navigate('/dashboard', { replace: true })

        closeModal()
      } catch (error) {
        console.log(error)
        toast({ status: 'error', title: 'Failed to confirm transaction' })
        setHasError(true)
        turnOffLoading()
        reportErrorToSentry(error)
      }
      turnOffLoading()
    } catch (error) {
      console.log(error)
      turnOffLoading()
      reportErrorToSentry(error)
    } finally {
      startTransition(() => {
        setLoadingText('')
      })
    }
  }

  const applyDiscount = async () => {
    if (!couponCode) {
      return toast({ status: 'warning', title: 'Please enter a coupon code' })
    }

    if (!selectedCardType?.id) {
      return toast({ status: 'warning', title: 'Please select a card type' })
    }

    try {
      setIsDiscountApplying(true)
      const urlQueryParams = new URLSearchParams({
        code: couponCode,
        agencyCode,
        cardTypeId: selectedCardType?.id,
      }).toString()
      const { value: couponValue } = await queryClient.fetchQuery<{ value: number }>({
        queryKey: [
          `discounts/validate?${urlQueryParams}`,
          couponCode,
          agencyCode,
          selectedCardType?.id,
        ],
        retry: false,
      })

      startTransition(() => {
        setDiscountValue(couponValue)
      })
      setIsDiscountApplying(false)
    } catch (err) {
      console.log(err)
      toast({ status: 'error', title: 'Invalid coupon code' })
      reportErrorToSentry(err)
      setIsDiscountApplying(false)
    }
  }

  const removeDiscount = () => {
    setCouponCode('')
    setDiscountValue(undefined)
  }

  return (
    <>
      <Modal onClose={closeModal} size="sm" isOpen={isOpen} isCentered closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent
          background="linear-gradient(135deg, #090909 12.06%, #1E1E1E 41.81%, #0B0B0B 70.59%, #212121 103.3%, #070707 121.42%)"
          borderRadius={6}
          borderColor="rgba(138, 138, 138, 0.18)"
          borderWidth={1}
        >
          <ModalCloseButton
            bgColor="white"
            borderRadius="100%"
            sx={{
              '> svg': {
                color: 'black',
              },
            }}
          />
          <ModalBody mt={7}>
            <ModalHeader padding={0}>
              <Text fontSize={24} fontWeight={500} textAlign="center">
                Apply Now
              </Text>

              <Divider
                filter={'drop-shadow(0px -1px 2.5px #000)'}
                borderColor={'#282828'}
                mt={9}
                mb={5}
                opacity={1}
              />
            </ModalHeader>

            {!isActivationRequired && (
              <Box
                bgGradient="linear-gradient(122deg, rgba(8, 8, 8, 0.16) 30.59%, rgba(22, 22, 22, 0.00) 74.22%)"
                borderBottomColor={
                  hasSufficientBalance
                    ? isPayingSufficientAmount
                      ? 'green.300'
                      : 'rgba(69, 69, 69)'
                    : 'red.300'
                }
                transition={'all 0.3s ease'}
                borderBottomWidth="0.25px"
                borderRadius={'6px'}
                w="full"
                px={6}
                py={3}
              >
                <NumberInput
                  value={depositAmount}
                  onChange={(value, _value) => setDepositAmount(value)}
                >
                  <NumberInputField placeholder="Enter deposit amount" border="none" />
                </NumberInput>

                <Flex justify="space-between" mt={6}>
                  <Text fontSize={12}>
                    Min Deposit: {depositFees?.minApplicationDepositAmount.toFixed(2)}
                  </Text>
                  <Suspense fallback={<></>}>
                    <Text fontSize={12} fontWeight={500}>
                      Available {(availableBalance ?? 0).toFixed(2)} {balanceData?.symbol}
                    </Text>
                  </Suspense>
                </Flex>
              </Box>
            )}

            {showDiscountSection && (
              <Flex gap={6} mt={6}>
                <Input
                  borderRadius={12}
                  borderColor="#53545B"
                  placeholder="Enter Coupon Code"
                  name="couponCode"
                  value={couponCode}
                  onChange={handleChange}
                />
                <Button
                  textColor={discountValue ? 'red.500' : '#A22BFF'}
                  fontWeight={700}
                  bgColor="transparent"
                  _hover={{ backgroundColor: 'transparent' }}
                  isLoading={isDiscountApplying}
                  onClick={discountValue ? removeDiscount : applyDiscount}
                >
                  {discountValue ? 'Remove Discount' : 'Apply Discount'}
                </Button>
              </Flex>
            )}

            <Box w="full" mt={6}>
              <Grid gridTemplateColumns={'2fr 2fr'}>
                <Text fontSize={18} fontWeight={700}>
                  Fee
                </Text>
                <Text textAlign="right">
                  {applicationFee} {token?.symbol}
                </Text>
              </Grid>

              <Grid gridTemplateColumns={'2fr 2fr'} mt={4}>
                <Flex flexDir="column">
                  <Text fontSize={18} fontWeight={700}>
                    Amount to transfer
                  </Text>
                  <Text fontFamily="Inter Variable" color="#B3B3B3">
                    (Fee + Deposit)
                  </Text>
                </Flex>
                <Text textAlign="right">
                  {amountToTransfer} {token?.symbol}
                </Text>
              </Grid>
            </Box>

            {hasError && (
              <>
                <Box bg="red.600" borderRadius="xl" p={4} mt={4}>
                  <Box fontSize="lg">An error has occurred</Box>
                  <Box mt={3}>
                    Something went wrong while confirming transaction. Please save the following
                    deposit reference and use it to recover your deposit.
                  </Box>
                  <Box fontWeight="bold" mt={4}>
                    Deposit reference:{' '}
                    <CopyButton
                      text={getShortString(depositReference, 6)}
                      value={depositReference ?? ''}
                    />
                  </Box>
                </Box>
              </>
            )}

            <Button
              p={0}
              fontWeight={700}
              onClick={handleRecoverDeposit}
              mt={7}
              h="unset"
              bg="transparent"
              _hover={{ bg: 'transparent' }}
            >
              <Text bgGradient="linear-gradient(180deg, #2649FF 0%, #8A16FF 100%)" bgClip="text">
                Recover Deposit
              </Text>
            </Button>

            <Checkbox
              isChecked={isConfirmed}
              onChange={e => setIsConfirmed(e.target.checked)}
              colorScheme="purple"
              mt={6}
              gap={2}
            >
              I hereby confirm that I have read and understand the policy and I can't withdraw the
              crypto back to my wallet which I topped up
            </Checkbox>
          </ModalBody>

          <ModalFooter pb={12} pt={6}>
            <NorpayButton
              w="full"
              bgGradient="linear-gradient(159deg, #5317FF 14.06%, #591ED2 94.29%)"
              fontSize={18}
              fontWeight={600}
              fontFamily="Inter Variable"
              py={4}
              onClick={handleApplyCard}
              isLoading={isLoading}
              loadingText={loadingText}
            >
              Get Your Card
            </NorpayButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <DepositRecoverModal
        isOpen={isRecoverModalOpen}
        onClose={onRecoverModalClose}
        cardTypeId={selectedCardType?.id}
        agencyCode={agencyCode}
      />
    </>
  )
}
