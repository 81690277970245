import { init as initFullStory } from '@fullstory/browser'
import Hotjar from '@hotjar/browser'

export function loadAnalytics() {
  const isProduction = process.env.REACT_APP_PRODUCTION !== 'false'

  if (isProduction) {
    if (process.env.REACT_APP_FULLSTORY_ORG_ID) {
      initFullStory({ orgId: process.env.REACT_APP_FULLSTORY_ORG_ID }, ({ sessionUrl }) =>
        console.log(`Started Full Story session: ${sessionUrl}`),
      )
    }

    if (process.env.REACT_APP_HOTJAR_SITEID && !Number.isNaN(process.env.REACT_APP_HOTJAR_SITEID)) {
      Hotjar.init(+process.env.REACT_APP_HOTJAR_SITEID, 6)
    }
  }
}
