import { Footer, Navbar } from 'components'
import ApplyCardLayout from 'layout/apply-card-layout'
import { Dashboard } from 'pages'
import AdminPanel from 'pages/AdminPanel/AdminPanel'
import ApplyCard from 'pages/ApplyCard/ApplyCard'
import KycForm from 'pages/ApplyCard/Kyc'
import CardActionsPage from 'pages/CardActionsPage/CardActionsPage'
import CardTransactions from 'pages/CardTransactions/CardTransactions'
import Home from 'pages/Home'
import UserInfoPage from 'pages/UserInfo/UserInfo'
import AuthProvider from 'providers/AuthProvider'
import AuthenticatedRoutes from 'providers/AuthenticatedRoutes'
import GlobalProvider from 'providers/GlobalProvider'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import * as Sentry from '@sentry/react'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export default function App() {
  return (
    <BrowserRouter>
      <Sentry.ErrorBoundary showDialog fallback={<></>}>
        <GlobalProvider>
          <AuthProvider>
            <Navbar />
            <SentryRoutes>
              <Route path="/" element={<Home />} />

              <Route Component={AuthenticatedRoutes}>
                <Route path="/user-info" Component={UserInfoPage} />

                <Route path="apply-card" Component={ApplyCardLayout}>
                  <Route index Component={ApplyCard} />
                  <Route path="kyc" Component={KycForm} />
                </Route>

                <Route path="/dashboard">
                  <Route index Component={Dashboard} />
                </Route>

                <Route path="/cards">
                  <Route path=":cardId/transactions" Component={CardTransactions} />
                  <Route path=":cardId/actions" Component={CardActionsPage} />
                </Route>
                <Route path="/admin" Component={AdminPanel} />
              </Route>
            </SentryRoutes>
            <Footer />
          </AuthProvider>
        </GlobalProvider>
      </Sentry.ErrorBoundary>
    </BrowserRouter>
  )
}
