import { Grid, GridItem } from '@chakra-ui/react'
import RenderCard from 'components/cards/RenderCard'
import { useEffect } from 'react'
import { CardName } from 'types/card'

export const cardListOrder = {
  [CardName.NorpayEURCard]: 3,
  [CardName.VirtualCard]: 1,
  [CardName.PhysicalCard]: 2,
}

interface CardSliderProps {
  autoAnimate?: boolean
  currentCard: CardName
  setCurrentCard: React.Dispatch<React.SetStateAction<CardName>>
  cardSliderSectionProps?: React.ComponentProps<typeof Grid>
}

export default function CardSlider({
  currentCard,
  setCurrentCard,
  autoAnimate = true,
  cardSliderSectionProps,
}: CardSliderProps) {
  useEffect(() => {
    if (!autoAnimate) return

    const timeOutRef = setInterval(() => {
      setCurrentCard(prevCard => {
        let possibleCurrentCardPosition = cardListOrder[prevCard] + 1

        if (possibleCurrentCardPosition > Object.keys(cardListOrder).length) {
          possibleCurrentCardPosition = 1
        }

        const possibleCurrentCard = Object.keys(cardListOrder).find(
          cardName => cardListOrder[cardName] === possibleCurrentCardPosition,
        ) as CardName

        return possibleCurrentCard
      })
    }, 3000)

    return () => clearInterval(timeOutRef)
  }, [currentCard, autoAnimate])

  const isNextCard = (cardName: CardName) => {
    if (cardListOrder[currentCard] + 1 > Object.keys(cardListOrder).length) {
      return cardListOrder[cardName] === 1
    }

    return cardListOrder[currentCard] + 1 === cardListOrder[cardName]
  }

  const isPrevCard = (cardName: CardName) => {
    if (cardListOrder[currentCard] - 1 <= 0) {
      return cardListOrder[cardName] === Object.keys(cardListOrder).length
    }

    return cardListOrder[currentCard] - 1 === cardListOrder[cardName]
  }

  const isUpcomingIndex = (cardName: CardName) => {
    const currentOrder = cardListOrder[currentCard]
    const upcomingOrder = cardListOrder[cardName]

    if (currentOrder === Object.keys(cardListOrder).length) {
      return upcomingOrder === 2
    }

    if (currentOrder === Object.keys(cardListOrder).length - 1) {
      return upcomingOrder === 1
    }

    return upcomingOrder >= currentOrder + 2
  }

  // const isPastCard = (cardName: CardName) => {
  //   const currentOrder = cardListOrder[currentCard]
  //   const upcomingOrder = cardListOrder[cardName]

  //   if (currentOrder === 1) {
  //     return upcomingOrder === Object.keys(cardListOrder).length - 1
  //   }

  //   if (currentOrder === 2) {
  //     return upcomingOrder === Object.keys(cardListOrder).length
  //   }

  //   return upcomingOrder === currentOrder - 2
  // }

  return (
    <Grid
      as="section"
      aria-label="card slider"
      templateColumns={'repeat(4, 1fr)'}
      gap={2}
      pos="relative"
      maxW="430px"
      {...cardSliderSectionProps}
    >
      <GridItem
        colStart={1}
        colEnd={3}
        display="inline-grid"
        pos="absolute"
        data-label="NorpayEURCard"
        opacity={currentCard === CardName.VirtualCard ? 0.7 : 0}
        filter={currentCard === CardName.VirtualCard ? 'blur(2px)' : ''}
        scale={0.9}
        transition="transform, opacity, scale 500ms ease-in-out"
      >
        <RenderCard cardName={CardName.NorpayEURCard} hideInfo />
      </GridItem>

      <GridItem
        colStart={2}
        colEnd={4}
        zIndex={currentCard === CardName.VirtualCard ? 5 : 'inherit'}
        pos="absolute"
        display="inline-grid"
        data-label="VirtualCard"
        translateX={
          isNextCard(CardName.VirtualCard)
            ? '50%'
            : isPrevCard(CardName.VirtualCard)
            ? '-50%'
            : '0%'
        }
        opacity={
          isPrevCard(CardName.VirtualCard) || isNextCard(CardName.VirtualCard)
            ? 0.7
            : isUpcomingIndex(CardName.VirtualCard)
            ? 0
            : 1
        }
        filter={
          isPrevCard(CardName.VirtualCard) || isNextCard(CardName.VirtualCard) ? 'blur(2px)' : ''
        }
        scale={currentCard === CardName.VirtualCard ? 1.2 : 0.9}
        transitionProperty="transform, opacity, scale"
        transitionDuration="500ms"
        transitionTimingFunction="ease-in"
        transform={
          'translateX(var(--chakra-translate-x)) scaleX(var(--chakra-scale-x)) scaleY(var(--chakra-scale-y))'
        }
      >
        <RenderCard cardName={CardName.VirtualCard} hideInfo height="116px" />
      </GridItem>

      <GridItem
        colStart={2}
        colEnd={4}
        zIndex={5}
        opacity={0}
        display="inline-grid"
        key={'virtual card with 0 opacity'}
        data-label="VirtualCard with 0 opacity"
      >
        <RenderCard cardName={CardName.VirtualCard} hideInfo height="116px" />
      </GridItem>

      <GridItem
        colStart={3}
        colEnd={5}
        zIndex={currentCard === CardName.PhysicalCard ? 5 : 'inherit'}
        pos="absolute"
        display="inline-grid"
        data-label="PhysicalCard"
        placeItems="center"
        opacity={
          isPrevCard(CardName.PhysicalCard) || isNextCard(CardName.PhysicalCard)
            ? 0.7
            : isUpcomingIndex(CardName.PhysicalCard)
            ? 0
            : 1
        }
        filter={
          isPrevCard(CardName.PhysicalCard) || isNextCard(CardName.PhysicalCard) ? 'blur(2px)' : ''
        }
        scale={currentCard === CardName.PhysicalCard ? 1.2 : 0.9}
        translateX={
          currentCard === CardName.PhysicalCard
            ? '-52%'
            : isPrevCard(CardName.PhysicalCard)
            ? '-100%'
            : '0%'
        }
        transitionProperty="transform, opacity, scale"
        transitionDuration="500ms"
        transitionTimingFunction="ease-in"
        transform={
          'translateX(var(--chakra-translate-x)) scaleX(var(--chakra-scale-x)) scaleY(var(--chakra-scale-y))'
        }
      >
        <RenderCard cardName={CardName.PhysicalCard} hideInfo />
      </GridItem>

      <GridItem
        colStart={3}
        colEnd={5}
        pos="absolute"
        zIndex={currentCard === CardName.NorpayEURCard ? 5 : 'inherit'}
        display="inline-grid"
        data-label="NorpayEURCard"
        opacity={
          isUpcomingIndex(CardName.NorpayEURCard)
            ? 0
            : isPrevCard(CardName.NorpayEURCard) || isNextCard(CardName.NorpayEURCard)
            ? 0.7
            : 1
        }
        scale={currentCard === CardName.NorpayEURCard ? 1.2 : 0.9}
        translateX={currentCard === CardName.NorpayEURCard ? '-52%' : '0%'}
        filter={
          isPrevCard(CardName.NorpayEURCard) || isNextCard(CardName.NorpayEURCard)
            ? 'blur(2px)'
            : ''
        }
        transitionProperty="transform, opacity, scale"
        transitionDuration="500ms"
        transitionTimingFunction="ease-in"
        transform={
          'translateX(var(--chakra-translate-x)) scaleX(var(--chakra-scale-x)) scaleY(var(--chakra-scale-y))'
        }
      >
        <RenderCard cardName={CardName.NorpayEURCard} hideInfo />
      </GridItem>
    </Grid>
  )
}
