import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from './AuthProvider'
import { Loader } from 'components'

export default function AuthenticatedRoutes() {
  const { isAuthenticating, isAuthenticated } = useAuth()

  if (isAuthenticating) {
    return <Loader />
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }

  return <Outlet />
}
