import { Button, Container, ContainerBox, Loader, Table } from 'components'
import { Box, Heading, useDisclosure, useToast } from '@chakra-ui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { IAgency, CreateAgency } from 'types/agency'
import type { TableColumn } from 'components/Table/Table'
import { api } from 'lib/query-client'
import CreateAgencyModal from './CreateAgencyModal'

const Agency = () => {
  const discountAgencyModal = useDisclosure()
  const toast = useToast()

  const {
    data: agencies,
    refetch: refetchAgencies,
    isPending,
  } = useQuery<IAgency[]>({
    queryKey: ['agency', 'getAllAgencies'],
  })

  const { mutateAsync: deleteAgency } = useMutation({
    mutationKey: ['deleteAgency'],
    mutationFn: ({ agencyId }: { agencyId: string }) => api.delete(`agency/${agencyId}`),
  })

  const { mutateAsync: createAgency } = useMutation({
    mutationKey: ['createAgency'],
    mutationFn: (data: { name: string; code: string; validUntil: string }) =>
      api.post('agency', { json: data }).json(),
  })

  const agencyCreate = async (data: CreateAgency) => {
    try {
      await createAgency(
        {
          ...data,
          validUntil: new Date(data.validUntil).toISOString(),
        },
        {
          onError: error => {
            toast({
              title: 'Error',
              description: error.message,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          },
        },
      )
      refetchAgencies()
      discountAgencyModal.onClose()
    } catch (e) {
      console.error(e)
    }
  }

  const agencyDelete = async (data: IAgency) => {
    try {
      await deleteAgency({ agencyId: data.id })
      refetchAgencies()
    } catch (e) {
      console.error(e)
    }
  }

  const columns: TableColumn<IAgency>[] = [
    {
      title: 'Name',
      render: agency => `${agency.name}`,
    },
    { title: 'Code', render: agency => agency.code },
    {
      title: 'Valid Till',
      render: agency => new Date(agency.validUntil).toUTCString(),
    },
    {
      title: 'Cards Count',
      render: agency => agency.cardsCount,
    },
    {
      title: 'Action',
      render: agency => (
        <Box>
          <Button variant="link" onClick={() => agencyDelete(agency)}>
            Delete
          </Button>
        </Box>
      ),
    },
  ]

  if (isPending) {
    return <Loader />
  }

  return (
    <>
      <Container mt={16}>
        <ContainerBox my={12} py={6} px={{ base: 4, lg: 12 }}>
          <Heading mb={6}>Agencies</Heading>
          <Button onClick={discountAgencyModal.onOpen} mr={6} mb={6}>
            Create Agency
          </Button>
          {agencies && agencies.length > 0 ? (
            <Table columns={columns} data={agencies} />
          ) : (
            <Box>No agencies created</Box>
          )}
        </ContainerBox>
      </Container>
      <CreateAgencyModal
        isOpen={discountAgencyModal.isOpen}
        onClose={discountAgencyModal.onClose}
        onCreate={agencyCreate}
      />
    </>
  )
}

export default Agency
