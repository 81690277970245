import type { SVGProps } from 'react'
const SvgSparkles = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={39} height={50} fill="none" {...props}>
    <path
      fill="url(#sparkles_svg__a)"
      d="M16.022 25.734c7.118-2.218 9.371-4.893 11.35-11.35 2.389 6.72 4.616 9.495 11.35 11.35-6.925 2.508-9.327 5.06-11.35 11.351-1.855-7.241-4.37-9.695-11.35-11.35"
      opacity={0.98}
    />
    <path
      fill="url(#sparkles_svg__b)"
      d="M.602 25.563c1.86-.58 2.45-1.28 2.967-2.967.624 1.756 1.206 2.481 2.967 2.967-1.81.655-2.439 1.322-2.967 2.967-.485-1.893-1.143-2.535-2.967-2.968"
      opacity={0.98}
    />
    <path
      fill="url(#sparkles_svg__c)"
      d="M27.795 3.742c1.968-.613 2.59-1.353 3.138-3.138.66 1.858 1.276 2.625 3.137 3.138-1.914.693-2.578 1.399-3.137 3.138-.513-2.002-1.208-2.68-3.138-3.138"
      opacity={0.98}
    />
    <path
      fill="url(#sparkles_svg__d)"
      d="M16.022 45.188c2.83-.883 3.726-1.946 4.513-4.514.95 2.673 1.835 3.776 4.513 4.514-2.754.996-3.71 2.011-4.513 4.513-.738-2.88-1.738-3.855-4.514-4.514"
      opacity={0.98}
    />
    <defs>
      <linearGradient
        id="sparkles_svg__a"
        x1={21.697}
        x2={33.047}
        y1={20.059}
        y2={31.41}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FAF0FF" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="sparkles_svg__b"
        x1={2.085}
        x2={5.052}
        y1={24.079}
        y2={27.046}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FAF0FF" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="sparkles_svg__c"
        x1={29.364}
        x2={32.502}
        y1={2.173}
        y2={5.311}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FAF0FF" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="sparkles_svg__d"
        x1={18.278}
        x2={22.791}
        y1={42.931}
        y2={47.444}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FAF0FF" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgSparkles
