import { TreasuryToken } from './token'

export type CardStatus =
  | 'not-applied'
  | 'pending'
  | 'activated'
  | 'freezing'
  | 'frozen'
  | 'unfreezing'
  | 'registered'
  | 'linked'
  | 'kyc-reviewing'
  | 'kyc-review-success'
  | 'kyc-review-rejected'
  | 'activating'
  | 'activation-failed'
  | 'cancelling-card'
  | 'cancelled-card'

export enum CardName {
  VirtualCard = 'Virtual',
  NorpayEURCard = 'Norpay Virtual (EUR)',
  PhysicalCard = 'Norpay Physical',
  VirtualApplePay = 'Virtual Apple Pay',
  MeshCard = 'Mesh Card',
}

export interface CardDetails {
  cardNumber: string
  expiration: string
  cvv: string
}

export interface CardDetailsUrl {
  url: string
  password: string
}

export interface TransactionMonth {
  month: string
  transactions: Transaction[]
}

export interface Transaction {
  credit: string
  debit: string
  description: string
  fee: string
  postingDate: string
  transactionDate: string
  merchantTradeNumber: string
  transactionAmount: string
  transactionCurrency: string
  transactionId: string
  type: number
}

export interface CardBalance {
  availableBalance: number
  cardCurrency: string
  currentBalance: number
}

enum CardHistoryActionStatus {
  pending = 'pending',
  completed = 'completed',
  failed = 'failed',
}

enum CardHistoryActionType {
  recharge = 'recharge',
  application = 'application',
  registration = 'registration',
  freeze = 'freeze',
  unfreeze = 'unfreeze',
}

export interface CardHistoryAction {
  id: string
  type: CardHistoryActionType
  status: CardHistoryActionStatus
  date: string
  amount?: string
  fee?: string
  reference?: string
  action?: 'deposit' | 'operation'
  token?: TreasuryToken
  formattedAmount?: string
  formattedFee?: string
}

export enum CardTypeStatus {
  enabled = 'enabled',
  disabled = 'disabled',
}

export interface CardType {
  id: string
  name: CardName
  providerCardTypeId: string
  applicationType: string
  status: CardTypeStatus
  rechargeFee: number
  applicationFee: string
  minRecharge: number
  minApplication: number
  limitMaxBalance: number
}

export interface Card {
  id: string
  status: string
  type: CardType
}

export interface CardStatistics {
  cardsTotalCount: number
  cardsActivatedCount: number
  cardsPendingCount: number
}
