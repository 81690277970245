import { Box, Flex, Text } from '@chakra-ui/react'
import SvgBnbSmartChain from 'assets/chains/BnbSmartChain'
import SvgEtherChain from 'assets/chains/EtherChain'
import SvgEtheriumChain from 'assets/chains/EtheriumChain'
import SvgPolygonChain from 'assets/chains/PolygonChain'

export default function SupportedChains() {
  return (
    <Box
      as="section"
      aria-label="supported chains"
      display="flex"
      flexDir="column"
      alignItems="center"
    >
      <Text fontWeight={700} lineHeight={5} textAlign="center">
        Supported Chains
      </Text>

      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        alignItems="center"
        columnGap={{ md: 11 }}
        my={{ md: 12 }}
      >
        <Flex
          bgColor={{ base: 'rgba(32, 32, 32, 0.58)', md: 'rgba(32, 32, 32)' }}
          borderRadius={20}
          gap={3}
          p={{ base: 3, md: 2 }}
          mt={{ base: 5, md: 0 }}
        >
          <SvgBnbSmartChain />
          <Text fontWeight={500} fontSize={20} lineHeight={9}>
            BNB Smart Chain
          </Text>
        </Flex>

        <Flex
          bgColor={{ base: 'rgba(34, 34, 34, 0.54)', md: 'rgba(34, 34, 34)' }}
          borderRadius={16}
          gap={3}
          p={2}
          mt={{ base: 2, md: 0 }}
        >
          <Flex gap={1}>
            <SvgEtherChain />
            <SvgPolygonChain />
            <SvgEtheriumChain />
          </Flex>
          <Text lineHeight={7}>Coming Soon</Text>
        </Flex>
      </Flex>
    </Box>
  )
}
