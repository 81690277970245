import type { SVGProps } from 'react'
const SvgPolygonChain = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27} height={27} fill="none" {...props}>
    <g clipPath="url(#polygon-chain_svg__a)">
      <path
        fill="#7950DD"
        d="M13.257.063C6.027.063.166 5.923.166 13.153s5.86 13.091 13.091 13.091 13.091-5.86 13.091-13.09S20.488.063 13.257.063m-4.071 5.47c.255-.043.517 0 .745.123l3.654 2.123a1.01 1.01 0 0 1 .545.925v1.43l-1.704 1.016V9.674a1.11 1.11 0 0 0-.554-.925L9.886 7.641a1.17 1.17 0 0 0-1.108 0L6.792 8.749a1.02 1.02 0 0 0-.554.925v2.306a1.11 1.11 0 0 0 .554.925l1.986 1.155a1.18 1.18 0 0 0 1.108 0l2.498-1.478 1.746-.97 2.498-1.477a1.18 1.18 0 0 1 1.108 0l3.694 2.122a1.02 1.02 0 0 1 .555.924v4.295a1.11 1.11 0 0 1-.55.923l-3.694 2.124a1.18 1.18 0 0 1-1.109 0L12.938 18.4a1.02 1.02 0 0 1-.554-.924v-1.477l1.659-.97v1.484a1.11 1.11 0 0 0 .554.923l1.986 1.108a1.17 1.17 0 0 0 1.108 0l1.985-1.154a1.02 1.02 0 0 0 .554-.923v-2.263a1.11 1.11 0 0 0-.554-.924l-1.985-1.108a1.18 1.18 0 0 0-1.108 0l-2.49 1.427-1.709 1.015-2.498 1.432a1.18 1.18 0 0 1-1.108 0l-3.694-2.129a1.02 1.02 0 0 1-.555-.92V8.705a.94.94 0 0 1 .6-.925l3.694-2.123q.172-.092.363-.123"
      />
    </g>
    <defs>
      <clipPath id="polygon-chain_svg__a">
        <path fill="#fff" d="M.166.063h26.182v26.182H.166z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgPolygonChain
