import type { SVGProps } from 'react'
const SvgEtherChain = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27} height={27} fill="none" {...props}>
    <g clipPath="url(#ether-chain_svg__a)">
      <path
        fill="#627EEA"
        d="M13.386 26.24c7.23 0 13.09-5.86 13.09-13.09S20.617.059 13.387.059.295 5.919.295 13.149s5.861 13.092 13.09 13.092"
      />
      <path fill="#fff" fillOpacity={0.602} d="M13.795 3.328v7.258l6.134 2.74z" />
      <path fill="#fff" d="M13.795 3.328 7.66 13.326l6.135-2.74z" />
      <path fill="#fff" fillOpacity={0.602} d="M13.795 18.033v4.932l6.138-8.492z" />
      <path fill="#fff" d="M13.795 22.965v-4.932l-6.135-3.56z" />
      <path fill="#fff" fillOpacity={0.2} d="m13.795 16.887 6.134-3.562-6.134-2.74z" />
      <path fill="#fff" fillOpacity={0.602} d="m7.66 13.325 6.135 3.562v-6.301z" />
    </g>
    <defs>
      <clipPath id="ether-chain_svg__a">
        <path fill="#fff" d="M.293.063h26.182v26.182H.293z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgEtherChain
