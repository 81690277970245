import React, { useState } from 'react'
import { Box, FormControl, FormLabel, Input, useBoolean } from '@chakra-ui/react'
import { Button, Modal } from 'components'
import type { CreateAgency } from 'types/agency'

interface Props {
  isOpen: boolean
  onClose: () => void
  onCreate: (data: CreateAgency) => Promise<void>
}

const CreateAgencyModal = ({ isOpen, onClose, onCreate }: Props) => {
  const [formData, setFormData] = useState<CreateAgency>({
    code: '',
    name: '',
    validUntil: '',
  })

  const [isLoading, { on: turnOnLoading, off: turnOffLoading }] = useBoolean()

  const isFormValid = () => {
    return formData.code !== '' && formData.validUntil !== '' && formData.name !== ''
  }

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const hadleCreate = async () => {
    try {
      turnOnLoading()
      await onCreate(formData)
      turnOffLoading()
    } catch (error) {
      console.error(error)
      turnOffLoading()
    }
  }

  return (
    <>
      <Modal title="Create Agency" isOpen={isOpen} onClose={onClose}>
        <Box>
          <FormControl isRequired mt={4}>
            <FormLabel>Name</FormLabel>
            <Input type="text" name="name" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Code</FormLabel>
            <Input type="text" name="code" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Date To</FormLabel>
            <Input type="date" name="validUntil" onChange={handleChange} />
          </FormControl>
          <Button
            mt={4}
            type="submit"
            onClick={hadleCreate}
            isLoading={isLoading}
            loadingText="Creating Agency"
            isDisabled={!isFormValid()}
          >
            Create Agency
          </Button>
        </Box>
      </Modal>
    </>
  )
}

export default CreateAgencyModal
