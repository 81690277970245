import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import '@fontsource-variable/inter'
import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit'
import '@rainbow-me/rainbowkit/styles.css'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Buffer } from 'buffer'
import 'font-load.css'
import 'index.css'
import { loadAnalytics } from 'lib/analytics'
import { queryClient } from 'lib/query-client'
import { chains, wagmiConfig } from 'lib/web3'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { theme } from 'styles/theme'
import { WagmiConfig } from 'wagmi'
import App from './App'
import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { captureConsoleIntegration, httpClientIntegration } from '@sentry/integrations'

loadAnalytics()

window.Buffer = window.Buffer || Buffer

const isProduction = process.env.NODE_ENV === 'production'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: isProduction,
  debug: !isProduction,
  attachStacktrace: true,
  enableTracing: true,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    httpClientIntegration({}),
  ],
  sendDefaultPii: true,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [process.env.REACT_APP_API_URL as string],
})

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains} theme={darkTheme()}>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider
            theme={theme}
            toastOptions={{
              defaultOptions: {
                duration: 1000,
              },
            }}
          >
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />

            <App />
          </ChakraProvider>
        </QueryClientProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>,
)
