import { ArrowBackIcon } from '@chakra-ui/icons'
import { Box, Flex, Heading, IconButton, SkeletonText, Text, VStack, theme } from '@chakra-ui/react'
import { useQueries } from '@tanstack/react-query'
import SvgIconCardActions from 'assets/icons/IconCardActions'
import dayjs from 'dayjs'
import { ethers } from 'ethers'
import { useNavigate } from 'react-router-dom'
import { CardHistoryAction } from 'types/card'

interface CardActionsProps {
  selectedCardId: string
  showBackButton?: boolean
}

const cardActionStatusToColor = {
  pending: theme.colors.orange[300],
  completed: theme.colors.green[300],
  failed: theme.colors.red[300],
}

export default function CardActions({ selectedCardId, showBackButton }: CardActionsProps) {
  const navigate = useNavigate()

  const { data: cardActions, isLoading } = useQueries({
    queries: [
      {
        queryKey: [`cards/${selectedCardId}/operations`, selectedCardId],
        enabled: Boolean(selectedCardId),
        staleTime: 5 * 60 * 1000,
      },
      {
        queryKey: [`cards/${selectedCardId}/deposits`, selectedCardId],
        enabled: Boolean(selectedCardId),
        staleTime: 5 * 60 * 1000,
      },
    ],
    combine([operations, deposits]) {
      const groupedCardActions = new Map<string, CardHistoryAction[]>()

      if (operations.isError || deposits.isError) {
        return {
          isLoading: false,
          data: groupedCardActions,
        }
      }

      if (operations.isLoading || deposits.isLoading) {
        return {
          isLoading: true,
          data: groupedCardActions,
        }
      }

      const cardOperations = (operations.data as any).map(
        operation =>
          ({
            action: 'operation',
            ...operation,
          } as unknown as CardHistoryAction),
      )

      const cardDeposits = (deposits.data as any).map(
        deposit =>
          ({
            action: 'deposit',
            ...deposit,
          } as unknown as CardHistoryAction),
      )

      const combinedActions = [...cardOperations, ...cardDeposits].sort((a, b) =>
        b.date.localeCompare(a.date),
      )

      for (const cardAction of combinedActions) {
        const date = dayjs(cardAction.date)
        const monthYear = date.format('MMMM YYYY')

        if (!groupedCardActions.has(monthYear)) {
          groupedCardActions.set(monthYear, [])
        }

        if (cardAction.action === 'deposit') {
          groupedCardActions.get(monthYear)!.push({
            ...cardAction,
            formattedAmount: ethers.formatUnits(cardAction.amount, cardAction.token.decimals),
            formattedFee: ethers.formatUnits(cardAction.fee, cardAction.token.decimals),
          })
          continue
        }

        groupedCardActions.get(monthYear)!.push(cardAction)
      }

      return {
        isLoading: false,
        data: groupedCardActions,
      }
    },
  })

  return (
    <Box
      bgGradient="linear-gradient(136deg, rgba(9, 9, 9, 0.32) 11.6%, rgba(11, 11, 11, 0.32) 58.61%, rgba(7, 7, 7, 0.32) 99.43%)"
      border="1px solid rgba(138, 138, 138, 0.18)"
      borderRadius={24}
      py={10}
      as="section"
      aria-label="card actions"
    >
      <Flex as="header" justify="space-between" px={{ base: 4, md: 6 }}>
        <Flex alignItems="center" gap={1}>
          {showBackButton && (
            <IconButton
              icon={<ArrowBackIcon />}
              aria-label="go back"
              onClick={() => navigate(-1)}
            />
          )}

          <SvgIconCardActions />
          <Heading fontSize={24} fontWeight={500} as="h1">
            Actions
          </Heading>
        </Flex>
        {/* <Button
          bg="transparent"
          _hover={{ backgroundColor: 'transparent' }}
          minW="auto"
          h="auto"
          p={0}
          fontFamily="Inter Variable"
          color="#C6C6C6"
          display={location.pathname === `/cards/${selectedCardId}/actions` ? 'none' : 'block'}
          onClick={() => navigate(`/cards/${selectedCardId}/actions`)}
        >
          View All
        </Button> */}
      </Flex>

      {isLoading && (
        <VStack spacing={4} mt={5} px={{ base: 4, md: 6 }}>
          {Array(5)
            .fill('')
            .map((_, i) => (
              <Flex
                key={i}
                w="full"
                borderBottom="0.269px solid rgba(69, 69, 69)"
                borderRadius="6px"
                bgGradient="linear-gradient(122deg, rgba(8, 8, 8, 0.17) 30.59%, rgba(22, 22, 22, 0.00) 74.22%)"
                py={3}
                px={5}
                justify="space-between"
              >
                <VStack spacing={1} alignItems="flex-start">
                  <SkeletonText noOfLines={1} w="100px" />
                  <SkeletonText noOfLines={1} w="100px" />
                </VStack>

                <VStack spacing={1} alignItems="flex-end">
                  <SkeletonText noOfLines={1} w="100px" />
                  <SkeletonText noOfLines={1} w="100px" />
                </VStack>
              </Flex>
            ))}
        </VStack>
      )}

      {Array.from(cardActions).map(([month, cardMonthlyActions]) => (
        <Box key={month} mt={6}>
          <Box pl={6} bgColor="rgba(11, 11, 11, 0.72)" py="2px">
            <Text color="#FAFAFA" fontSize={12}>
              {month}
            </Text>
          </Box>

          <VStack spacing={4} mt={5} px={{ base: 4, md: 6 }}>
            {cardMonthlyActions.map(cardAction => (
              <Flex
                key={cardAction.id}
                w="full"
                borderBottom="0.269px solid rgba(69, 69, 69)"
                borderRadius="6px"
                bgGradient="linear-gradient(122deg, rgba(8, 8, 8, 0.17) 30.59%, rgba(22, 22, 22, 0.00) 74.22%)"
                py={3}
                px={{ base: 2, md: 5 }}
                justify="space-between"
              >
                <VStack spacing={1} alignItems="flex-start">
                  <Text fontWeight={500} textTransform="capitalize">
                    {cardAction.type}
                  </Text>
                  <Text fontSize={12} data-date={cardAction.date}>
                    {dayjs(cardAction.date).format('MM/DD/YY hh:mm')}
                  </Text>
                </VStack>

                <VStack spacing={1} alignItems="flex-end">
                  {cardAction.action === 'operation' ? (
                    <Text
                      fontWeight={700}
                      data-status={cardAction.status}
                      textColor={cardActionStatusToColor[cardAction.status]}
                    >
                      {cardAction.status}
                    </Text>
                  ) : (
                    <>
                      <Text
                        fontWeight={700}
                        data-status={cardAction.status}
                        textColor={cardActionStatusToColor[cardAction.status]}
                        textAlign="right"
                      >
                        {(Number(cardAction?.amount) ?? 0) > 0
                          ? `${parseFloat(cardAction.formattedAmount ?? '0').toFixed(2)} ${
                              cardAction.token?.name
                            }`
                          : cardAction.status}
                      </Text>
                      {!!cardAction.fee && (
                        <Text fontSize={12}>
                          Fee {parseFloat(cardAction.formattedFee ?? '0').toFixed(2)}{' '}
                          {cardAction.token?.name}
                        </Text>
                      )}
                    </>
                  )}
                </VStack>
              </Flex>
            ))}
          </VStack>
        </Box>
      ))}
    </Box>
  )
}
