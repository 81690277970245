import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  Image,
  Input,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { Country } from 'types/country'

interface CountryCodeSelectProps {
  value: string
  options: Country[]
  isDisabled?: boolean
  onChange: (value: string) => void
}

export default function CountryCodeSelect({
  options: countries,
  value,
  onChange,
  isDisabled,
}: CountryCodeSelectProps) {
  const selectedCountry = countries.find(country => country.alpha3 === value)

  const [options, setOptions] = useState<Country[]>(() => countries)
  const [searchValue, setSearchValue] = useState('')

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleSelect = (country: Country) => () => {
    onChange(country.alpha3)
    setSearchValue('')
    onClose()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
    setOptions(
      countries.filter(
        country =>
          country.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
          country.phoneCode.toString().includes(event.target.value.toLowerCase()),
      ),
    )
  }

  return (
    <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen} placement="bottom-start">
      <PopoverTrigger>
        <Button
          rightIcon={<ChevronDownIcon />}
          padding={0}
          h="unset"
          minW={28}
          bg="transparent"
          _hover={{ background: 'transparent' }}
          isDisabled={isDisabled}
        >
          <Flex alignItems="center" gap={2}>
            <Image
              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedCountry?.alpha2}.svg`}
              width={6}
              height={4}
              alt={selectedCountry?.name}
            />
            <Text>+{selectedCountry?.phoneCode}</Text>
          </Flex>
        </Button>
      </PopoverTrigger>
      <PopoverContent bg="rgb(18, 18, 18)" w="40">
        <PopoverHeader>
          <Input
            value={searchValue}
            onChange={handleChange}
            placeholder="Search.."
            required={false}
            name="search"
          />
        </PopoverHeader>
        <PopoverBody maxH={60} overflowY="scroll">
          <List spacing={4}>
            {options.map(country => (
              <Tooltip label={country.name} key={country.alpha3}>
                <ListItem display="flex" gap={2} as="button" onClick={handleSelect(country)}>
                  <Image
                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.alpha2}.svg`}
                    width={6}
                    height={4}
                    alt={country.name}
                  />{' '}
                  <Text>+{country.phoneCode}</Text>
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
